/**
 * Named this as the 'Legacy' theme as it's a direct copy of the styles from the Umbraco site.
 * A New theme will be created with Kas
 */
import { createTheme } from '@mui/material/styles'

// Update MUI Theme breakpoints
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    mdplus: true
    lg: true
    xl: true
    xxl: true
    xxxl: true
    x4k: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/styles' {
  interface TypographyVariants {
    subHeading: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    subHeading?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subHeading: true
  }
}

export const colors = {
  white: '#FFF',
  black: '#33383a',
  lightGrey: '#f7f7f7',
  grey: '#eee',
  mediumGrey: '#92908c',
  darkGrey: '#444',
  darkestGrey: '#333',
  lightBlue: '#e8f6fd',
  darkBlue: '#186fd6',
  accessibleBlue: '#1F2ECF',
  hoverBlue: '#0ca1eb',
  hoverBlueHovered: '#53d2f4',
  blue: '#0C9CE4',
  yellow: '#ffed00',
  darkGreen: '#0fb799',
  lightGreen: '#EBF8F4',
  errorRed: '#BA1A1A'
}

export const fonts = {
  medium: '"Apax Medium", Helvetica, sans-serif',
  regular: '"Apax Regular", Helvetica, sans-serif',
  bold: '"Apax Bold", Helvetica, sans-serif',
  light: '"Apax Light", Helvetica, sans-serif'
}

export const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 768,
      mdplus: 960,
      lg: 1024,
      xl: 1280,
      xxl: 1920,
      xxxl: 2560,
      x4k: 3840,
      // These are from ZutoUi-V1 Theme but typescript is complaining that these are required due to these types extending the Mui types in the Theme level.
      xsDesktop: 1280,
      desktop: 1440,
      wide: 1920,
      ultraWide: 3840
    }
  },
  // This is complaining because it's expecting all of the typography options from ZutoUi-V1 (Since it's declared as required in Npm package, but we don't want them in this Legacy Theme.)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  typography: {
    fontFamily: [fonts.regular, fonts.medium, fonts.bold, fonts.light].join(',')
  },
  palette: {
    primary: {
      light: colors.lightBlue,
      main: colors.blue,
      dark: colors.darkBlue
    },
    secondary: {
      main: colors.yellow
    },
    background: {
      default: colors.white,
      paper: colors.grey
    }
  },
  // Important: The order of the spacing MUST stay the same. You can add extra values to the end but better to round up or down if possible.
  spacing: [0, 5, 10, 15, 20, 30, 40, 60, 70, 80, 4, 8, 12, 16, 24],
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.accessibleBlue,
          fontWeight: 600,
          textDecoration: 'none',
          '&:hover': {
            color: '#0C0FA3'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: colors.white,
          color: theme.palette.primary.main,
          border: 'none',
          boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
          borderRadius: 360,
          padding: `${theme.spacing(2)}`,
          minWidth: 125,
          fontSize: 16,
          fontWeight: 400,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            border: 'none',
            boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`
          }
        }),
        contained: () => ({
          backgroundColor: colors.blue,
          color: colors.white,
          transition: '.2s ease-in-out',
          '&:hover': {
            backgroundColor: colors.darkBlue,
            outline: 0,
            color: colors.white
          }
        })
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: colors.white,
          boxShadow: 'unset',
          borderTop: `1px solid ${colors.grey}`,
          p: {
            marginBottom: theme.spacing(3),
            '&:first-of-type': {
              marginTop: 0
            }
          },
          li: {
            marginBottom: theme.spacing(3)
          },
          '&.Mui-expanded': {
            margin: 0
          }
        })
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          p: {
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 0,
            [theme.breakpoints.up('sm')]: {
              fontSize: 18
            }
          }
        })
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} 0`
        })
      }
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(3)} 0`,
          right: 5,
          marginTop: '-1px',
          '.MuiIconButton-root': {
            padding: 0
          }
        })
      }
    }
  }
})

// Typography styles
Theme.typography.h1 = {
  fontSize: 30,
  marginBottom: Theme.spacing(5),
  lineHeight: '34px',
  fontFamily: fonts.bold,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 34,
    lineHeight: '38px'
  }
}
Theme.typography.h2 = {
  fontSize: 22,
  marginBottom: Theme.spacing(4),
  lineHeight: '32px',
  fontFamily: fonts.bold,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 26,
    lineHeight: '36px'
  }
}
Theme.typography.h3 = {
  fontSize: 20,
  marginBottom: Theme.spacing(4),
  lineHeight: '30px',
  fontFamily: fonts.bold,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 22,
    lineHeight: '34px'
  }
}
Theme.typography.h4 = {
  fontSize: 18,
  marginBottom: Theme.spacing(4),
  lineHeight: '28px',
  fontFamily: fonts.bold,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 20,
    lineHeight: '30px'
  }
}
Theme.typography.h5 = {
  fontSize: 12,
  marginBottom: 0,
  lineHeight: '28px',
  fontFamily: fonts.bold,
  color: colors.darkestGrey
}
Theme.typography.body1 = {
  marginBottom: Theme.spacing(4),
  fontFamily: fonts.regular,
  lineHeight: '26px'
}
Theme.typography.body2 = {
  marginBottom: Theme.spacing(4),
  fontFamily: fonts.regular,
  fontSize: 14
}
Theme.typography.subHeading = {
  fontSize: 18,
  marginBottom: Theme.spacing(4),
  lineHeight: '28px',
  fontFamily: fonts.bold,
  [Theme.breakpoints.up('sm')]: {
    fontSize: 20,
    lineHeight: '30px'
  }
}
Theme.typography.subtitle1 = {
  fontSize: 12,
  fontWeight: 600,
  [Theme.breakpoints.up('md')]: {
    fontSize: 16
  }
}

export default Theme
