'use client'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { IconButton, List, ListItem, Typography, styled } from '@mui/material'
import Link from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { colors } from '../../../Theme/LegacyTheme'
import { LinksList } from '@/types/UI'

const NextLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: 0,
  color: colors.black,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'block',
  width: '100%',
  '& :hover': {
    color: colors.hoverBlue
  }
}))

const LinksListWithChevrons: React.FC<LinksList> = ({ title, items }) => {
  const lengthToCompareTo = items.length - 1
  const lastChild = (index: number) => index == lengthToCompareTo
  return (
    <>
      {title && (
        <Typography variant={title.variant ?? 'h5'} component={title.component ?? 'h4'}>
          {title.text}
        </Typography>
      )}
      <List component="ul" sx={{ marginBottom: 3 }}>
        {items.map((item, index) => (
          <ListItem
            key={uuidv4()}
            sx={{ paddingLeft: 0, paddingRight: 3 }}
            divider={!lastChild(index)}
            secondaryAction={
              <IconButton component="span" aria-label="Chevron-right icon" edge="end" disabled>
                <ChevronRightIcon sx={{ color: colors.blue }} />
              </IconButton>
            }
          >
            <NextLink
              href={item.link}
              target={item.target || '_blank'}
              aria-label={`Navigate to ${item.text}`}
              role="link"
              sx={{ fontWeight: 400, padding: 0 }}
            >
              {item.text}
            </NextLink>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default LinksListWithChevrons
