const trustpilotSrc = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'

export const loadTrustpilotScript = () => {
  if (document) {
    const scriptElement = document.querySelector(`script[src="${trustpilotSrc}"]`)
    if (!scriptElement) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = trustpilotSrc
      script.defer = true
      document.head.appendChild(script)
    }
  }
}
