'use client'
import * as React from 'react'
import { Stack, Card, CardContent, Button, Typography, styled } from '@mui/material'
import LinksListWithChevrons from '../LinksListWithChevrons/LinksListWithChevrons'
import { CardProps } from '@/types/UI'
import { convertToKebabCase } from '../../../utils/utils'
import { dFlexCenter } from '../../../Theme/UtilityStyles'
import { colors } from '../../../Theme/LegacyTheme'

const IconWrapper = styled('div')(({ theme }) => ({
  ...dFlexCenter,
  backgroundColor: colors.lightGreen,
  borderRadius: '100%',
  minWidth: theme.spacing(7),
  minHeight: theme.spacing(7),
  marginRight: theme.spacing(1)
}))

const SingleCard: React.FC<CardProps> = ({ data, styleOverrides, withBoxShadow }) => {
  const boxShadow = withBoxShadow ? { boxShadow: '0 7px 50px -20px rgba(0,0,0,.2)' } : ''
  const id = convertToKebabCase(data.title.text)
  return (
    <Card
      sx={{
        padding: (theme) => theme.spacing(4),
        ...styleOverrides,
        ...boxShadow,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      id={id}
      data-testid={id}
    >
      <CardContent sx={{ padding: 0, flexGrow: 1 }}>
        <Stack
          alignItems={{ xs: 'center', md: 'flex-start' }}
          justifyContent="left"
          direction={{ xs: 'row', md: 'column' }}
          gap={2}
          mb={3}
        >
          {data.icon && <IconWrapper>{data.icon}</IconWrapper>}
          <Typography
            variant={data.title.variant ?? 'h3'}
            component={data.title.component ?? 'h3'}
            sx={{ fontWeight: 700, margin: 0 }}
          >
            {data.title.text}
          </Typography>
        </Stack>
        <Typography variant="body2" mb={data.linksList || data.button ? 3 : 0}>
          {data.description}
        </Typography>
        {data.linksList && <LinksListWithChevrons {...data.linksList} />}
      </CardContent>
      {data.button && (
        <Button
          role="link"
          aria-label={data.button.ariaLabel}
          href={data.button.buttonLink}
          sx={{ width: '100%', textAlign: 'center', lineHeight: 1.25, alignSelf: 'flex-end' }}
          id={data.button.buttonId}
          data-testid={data.button.buttonId}
          variant={data.button.buttonVariant ?? 'outlined'}
          tabIndex={data.tabIndex}
        >
          {data.button.buttonText}
        </Button>
      )}
    </Card>
  )
}

export default SingleCard
