export const dFlexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const dFlexSpaceBetween = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}

export const dFlexSpaceAround = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
}

export const dFlexSpaceEvenly = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
}

export const layoutMaxWidth = {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 20px',
    '@media only screen and (min-width: 768px)': {
        paddingLeft: '32px',
        paddingRight: '32px',
        maxWidth: 1264
    }
}
