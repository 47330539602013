import * as React from 'react'
import { Grid } from '@mui/material'
import SingleCard from './SingleCard'
import { v4 as uuidv4 } from 'uuid'
import { CardGridProps } from '../../../types/UI'

const CardGrid: React.FC<CardGridProps> = ({
  cardsData,
  lgCols,
  mdCols,
  smCols,
  xsCols = 12,
  styleOverrides,
  withBoxShadow
}) => {
  return (
    <Grid
      container
      spacing={{ xs: 4, xl: 6 }}
      justifyContent="center"
      alignItems="stretch"
      mt={4}
      mb={4}
    >
      {cardsData.map((card) => (
        <Grid item key={uuidv4()} xs={xsCols} sm={smCols} md={mdCols} lg={lgCols}>
          <SingleCard data={card} styleOverrides={styleOverrides} withBoxShadow={withBoxShadow} />
        </Grid>
      ))}
    </Grid>
  )
}

export default CardGrid
