import $console from './$console'

/**
 * Parses a JWT (JSON Web Token) from an authorization header string.
 * The function expects the header to start with 'Bearer', followed by the JWT.
 * If the header is in the correct format, the token is decoded from URL encoding and base64 to retrieve the payload.
 * If any error occurs during parsing, or if the format is incorrect, an empty object is returned.
 *
 * @param {string | undefined} authorizationHeader - The authorization header containing the 'Bearer' token.
 *   If `undefined`, the function returns an empty object.
 * @returns {object} - The decoded JWT payload as a JavaScript object, or an empty object if the token cannot be parsed.
 */
export const parseJWT = (authorizationHeader: string | undefined) => {
  try {
    if (authorizationHeader?.split(' ')[0] === 'Bearer') {
      const urlEncodedToken = authorizationHeader?.split(' ')[1]
      const token = decodeURIComponent(urlEncodedToken)
      const dataToken = token.split('.')[1]
      const dataBuffer = Buffer.from(dataToken, 'base64')
      const dataString = dataBuffer.toString('ascii')
      return JSON.parse(dataString)
    }
    return {}
  } catch (err) {
    $console.error(err)
    return {}
  }
}

/**
 *
 * @param str String with words and spaces
 * @returns The string without spaces between words, and each new word capitalized (with rest of word lowercase)
 */
export const replaceSpacesWithPascalCase = (str: string): string => {
  return str
    .replace(/[^\w\s]/gi, '') // Remove non-alphanumeric characters
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('')
}

/**
 * Converts a string to kebab case by replacing spaces with hyphens and converting to lowercase.
 *
 * @param {string} title - The string to be converted.
 * @returns The kebab-cased string.
 */
export const convertToKebabCase = (str: string) => {
  return str
    .replace(/[^\w\s]/gi, ' ') // Replace non-alphanumeric characters for spaces
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .toLowerCase()
}
