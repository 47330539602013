'use client'
import React from 'react'
import { GlobalStyles as MuiGlobalStyles } from '@mui/material'
import { colors } from '../src/Theme/LegacyTheme'

const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={(theme) => ({
      body: {
        margin: 0,
        padding: 0,
        backgroundColor: theme.palette.background.default,
        color: colors.black,
        fontFamily: theme.typography.fontFamily,
        overflowX: 'hidden'
      },
      ul: {
        marginTop: 0,
        marginBottom: theme.spacing(4)
      },
      a: {
        fontWeight: 400,
        transition: 'color .2s ease-in-out',
        color: colors.accessibleBlue,
        textDecoration: 'none',
        '&:hover': {
          color: '#0C0FA3'
        }
      },
      main: {
        a: {
          fontWeight: 600
        }
      },
      p: {
        margin: 0
      }
    })}
  />
)

export default GlobalStyles
